import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import './AdminHome.css';

const AdminHome = (props) => {
    return (
        <div>
            
        </div>
    )
}

export default connect(null, null)(AdminHome)
