export const systemConstants = {
    SET_MAIN_SCREEN: 'SET_MAIN_SCREEN',
    SET_SCALE: 'SET_SCALE',
    SET_USER: 'SET_USER',
    SET_LOGIN_MESSAGE: 'SET_LOGIN_MESSAGE',
    SET_TESTING_SWITCH: 'SET_TESTING_SWITCH',
}

export const companyConstants = {
    SET_PAGES: 'SET_PAGES',
    SET_SELECTED_PAGE_INDEX: 'SET_SELECTED_PAGE_INDEX',

    SET_LOGIN_SCREEN_FOCUSED: 'SET_LOGIN_SCREEN_FOCUSED',
    SET_MAIN_COMPANY_SCREEN_FOCUSED: 'SET_MAIN_COMPANY_SCREEN_FOCUSED',
    SET_CUSTOMER_T_SCREEN_FOCUSED: 'SET_CUSTOMER_T_SCREEN_FOCUSED',
    SET_DISPATCH_SCREEN_FOCUSED: 'SET_DISPATCH_SCREEN_FOCUSED',
    SET_CUSTOMER_SCREEN_FOCUSED: 'SET_CUSTOMER_SCREEN_FOCUSED',
    SET_CARRIER_SCREEN_FOCUSED: 'SET_CARRIER_SCREEN_FOCUSED',
    SET_LOAD_BOARD_SCREEN_FOCUSED: 'SET_LOAD_BOARD_SCREEN_FOCUSED',
    SET_INVOICE_SCREEN_FOCUSED: 'SET_INVOICE_SCREEN_FOCUSED',
    SET_REPORTS_SCREEN_FOCUSED: 'SET_REPORTS_SCREEN_FOCUSED',
    SET_COMPANY_OPENED_PANELS: 'SET_COMPANY_OPENED_PANELS',
    SET_ADMIN_COMPANY_OPENED_PANELS: 'SET_COMPANY_OPENED_PANELS',
    SET_COMPANY_HOME_PANELS: 'SET_COMPANY_HOME_PANELS',
    SET_CUSTOMERS_T_PANELS: 'SET_CUSTOMERS_T_PANELS',
}
 
export const adminConstants = {
    SET_PAGES: 'SET_ADMIN_PAGES',
    SET_SELECTED_PAGE_INDEX: 'SET_ADMIN_SELECTED_PAGE_INDEX',
    SET_MAIN_ADMIN_SCREEN_FOCUSED: 'SET_ADMIN_MAIN_COMPANY_SCREEN_FOCUSED',
    SET_DISPATCH_SCREEN_FOCUSED: 'SET_ADMIN_DISPATCH_SCREEN_FOCUSED',
    SET_CUSTOMER_SCREEN_FOCUSED: 'SET_ADMIN_CUSTOMER_SCREEN_FOCUSED',
    SET_CARRIER_SCREEN_FOCUSED: 'SET_ADMIN_CARRIER_SCREEN_FOCUSED',
    SET_REPORTS_SCREEN_FOCUSED: 'SET_ADMIN_REPORTS_SCREEN_FOCUSED',
    SET_SETUP_COMPANY_SCREEN_FOCUSED: 'SET_ADMIN_SETUP_COMPANY_SCREEN_FOCUSED',
    SET_INVOICE_SCREEN_FOCUSED: 'SET_ADMIN_INVOICE_SCREEN_FOCUSED',
    SET_ADMIN_OPENED_PANELS: 'SET_ADMIN_OPENED_PANELS',
    SET_ADMIN_HOME_PANELS: 'SET_ADMIN_HOME_PANELS',
}

export const customersConstants = {
    SET_CUSTOMERS: 'SET_CUSTOMERS',
    SET_SELECTED_CUSTOMER: 'SET_SELECTED_CUSTOMER',
    SET_CUSTOMER_PANELS: 'SET_CUSTOMER_PANELS',
    SET_SELECTED_CONTACT: 'SET_SELECTED_CONTACT',
    SET_SELECTED_NOTE: 'SET_SELECTED_NOTE',
    SET_SELECTED_DIRECTION: 'SET_SELECTED_DIRECTION',
    SET_CONTACT_SEARCH: 'SET_CONTACT_SEARCH',
    SET_CUSTOMER_SEARCH: 'SET_CUSTOMER_SEARCH',
    SET_AUTOMATIC_EMAILS_TO: 'SET_AUTOMATIC_EMAILS_TO',
    SET_AUTOMATIC_EMAILS_CC: 'SET_AUTOMATIC_EMAILS_CC',
    SET_AUTOMATIC_EMAILS_BCC: 'SET_AUTOMATIC_EMAILS_BCC',
    SET_SHOWING_CONTACT_LIST: 'SET_SHOWING_CONTACT_LIST',
    SET_CUSTOMER_CONTACTS: 'SET_CUSTOMER_CONTACTS',
    SET_CONTACT_SEARCH_CUSTOMER: 'SET_CONTACT_SEARCH_CUSTOMER',
    SET_IS_EDITING_CONTACT: 'SET_IS_EDITING_CONTACT',
    SET_SELECTED_DOCUMENT: 'SET_SELECTED_DOCUMENT',
    SET_DOCUMENT_TAGS: 'SET_DOCUMENT_TAGS',
    SET_SELECTED_DOCUMENT_NOTE: 'SET_SELECTED_DOCUMENT_NOTE',

    SET_ADMIN_CUSTOMERS: 'SET_ADMIN_CUSTOMERS',
    SET_ADMIN_SELECTED_CUSTOMER: 'SET_ADMIN_SELECTED_CUSTOMER',
    SET_ADMIN_CUSTOMER_PANELS: 'SET_ADMIN_CUSTOMER_PANELS',
    SET_ADMIN_SELECTED_CONTACT: 'SET_ADMIN_SELECTED_CONTACT',
    SET_ADMIN_SELECTED_NOTE: 'SET_ADMIN_SELECTED_NOTE',
    SET_ADMIN_SELECTED_DIRECTION: 'SET_ADMIN_SELECTED_DIRECTION',
    SET_ADMIN_CONTACT_SEARCH: 'SET_ADMIN_CONTACT_SEARCH',
    SET_ADMIN_CUSTOMER_SEARCH: 'SET_ADMIN_CUSTOMER_SEARCH',
    SET_ADMIN_AUTOMATIC_EMAILS_TO: 'SET_ADMIN_AUTOMATIC_EMAILS_TO',
    SET_ADMIN_AUTOMATIC_EMAILS_CC: 'SET_ADMIN_AUTOMATIC_EMAILS_CC',
    SET_ADMIN_AUTOMATIC_EMAILS_BCC: 'SET_ADMIN_AUTOMATIC_EMAILS_BCC',
    SET_ADMIN_SHOWING_CONTACT_LIST: 'SET_ADMIN_SHOWING_CONTACT_LIST',
    SET_ADMIN_CUSTOMER_CONTACTS: 'SET_ADMIN_CUSTOMER_CONTACTS',
    SET_ADMIN_CONTACT_SEARCH_CUSTOMER: 'SET_ADMIN_CONTACT_SEARCH_CUSTOMER',
    SET_ADMIN_IS_EDITING_CONTACT: 'SET_ADMIN_IS_EDITING_CONTACT',
    SET_ADMIN_SELECTED_DOCUMENT: 'SET_ADMIN_SELECTED_DOCUMENT',
    SET_ADMIN_DOCUMENT_TAGS: 'SET_ADMIN_DOCUMENT_TAGS',
    SET_ADMIN_SELECTED_DOCUMENT_NOTE: 'SET_ADMIN_SELECTED_DOCUMENT_NOTE',

    SET_BILL_TO_COMPANIES: 'SET_BILL_TO_COMPANIES',
    SET_SELECTED_BILL_TO_COMPANY_INFO: 'SET_SELECTED_BILL_TO_COMPANY_INFO',
    SET_SELECTED_BILL_TO_COMPANY_CONTACT: 'SET_SELECTED_BILL_TO_COMPANY_CONTACT',
    SET_BILL_TO_COMPANY_SEARCH: 'SET_BILL_TO_COMPANY_SEARCH',
    SET_SELECTED_BILL_TO_COMPANY_NOTE: 'SET_SELECTED_BILL_TO_COMPANY_NOTE',
    SET_SELECTED_BILL_TO_COMPANY_DIRECTION: 'SET_SELECTED_BILL_TO_COMPANY_DIRECTION',
    SET_BILL_TO_COMPANY_CONTACT_SEARCH: 'SET_BILL_TO_COMPANY_CONTACT_SEARCH',
    SET_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_BILL_TO_COMPANY_SHOWING_CONTACT_LIST: 'SET_BILL_TO_COMPANY_SHOWING_CONTACT_LIST',
    SET_BILL_TO_COMPANY_CONTACTS: 'SET_BILL_TO_COMPANY_CONTACTS',
    SET_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_BILL_TO_COMPANY_IS_EDITING_CONTACT: 'SET_BILL_TO_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_BILL_TO_COMPANY_DOCUMENT: 'SET_SELECTED_BILL_TO_COMPANY_DOCUMENT',
    SET_BILL_TO_COMPANY_DOCUMENT_TAGS: 'SET_BILL_TO_COMPANY_DOCUMENT_TAGS',
    SET_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE',

    SET_LB_BILL_TO_COMPANIES: 'SET_LB_BILL_TO_COMPANIES',
    SET_LB_SELECTED_BILL_TO_COMPANY_INFO: 'SET_LB_SELECTED_BILL_TO_COMPANY_INFO',
    SET_LB_SELECTED_BILL_TO_COMPANY_CONTACT: 'SET_LB_SELECTED_BILL_TO_COMPANY_CONTACT',
    SET_LB_BILL_TO_COMPANY_SEARCH: 'SET_LB_BILL_TO_COMPANY_SEARCH',
    SET_LB_SELECTED_BILL_TO_COMPANY_NOTE: 'SET_LB_SELECTED_BILL_TO_COMPANY_NOTE',
    SET_LB_SELECTED_BILL_TO_COMPANY_DIRECTION: 'SET_LB_SELECTED_BILL_TO_COMPANY_DIRECTION',
    SET_LB_BILL_TO_COMPANY_CONTACT_SEARCH: 'SET_LB_BILL_TO_COMPANY_CONTACT_SEARCH',
    SET_LB_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_LB_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_LB_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_LB_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_LB_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_LB_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_LB_BILL_TO_COMPANY_SHOWING_CONTACT_LIST: 'SET_LB_BILL_TO_COMPANY_SHOWING_CONTACT_LIST',
    SET_LB_BILL_TO_COMPANY_CONTACTS: 'SET_LB_BILL_TO_COMPANY_CONTACTS',
    SET_LB_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_LB_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_LB_BILL_TO_COMPANY_IS_EDITING_CONTACT: 'SET_LB_BILL_TO_COMPANY_IS_EDITING_CONTACT',
    SET_LB_SELECTED_BILL_TO_COMPANY_DOCUMENT: 'SET_LB_SELECTED_BILL_TO_COMPANY_DOCUMENT',
    SET_LB_BILL_TO_COMPANY_DOCUMENT_TAGS: 'SET_LB_BILL_TO_COMPANY_DOCUMENT_TAGS',
    SET_LB_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE: 'SET_LB_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE',

    SET_INVOICE_BILL_TO_COMPANIES: 'SET_INVOICE_BILL_TO_COMPANIES',
    SET_INVOICE_SELECTED_BILL_TO_COMPANY_INFO: 'SET_INVOICE_SELECTED_BILL_TO_COMPANY_INFO',
    SET_INVOICE_SELECTED_BILL_TO_COMPANY_CONTACT: 'SET_INVOICE_SELECTED_BILL_TO_COMPANY_CONTACT',
    SET_INVOICE_BILL_TO_COMPANY_SEARCH: 'SET_INVOICE_BILL_TO_COMPANY_SEARCH',
    SET_INVOICE_SELECTED_BILL_TO_COMPANY_NOTE: 'SET_INVOICE_SELECTED_BILL_TO_COMPANY_NOTE',
    SET_INVOICE_SELECTED_BILL_TO_COMPANY_DIRECTION: 'SET_INVOICE_SELECTED_BILL_TO_COMPANY_DIRECTION',
    SET_INVOICE_BILL_TO_COMPANY_CONTACT_SEARCH: 'SET_INVOICE_BILL_TO_COMPANY_CONTACT_SEARCH',
    SET_INVOICE_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_INVOICE_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_INVOICE_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_INVOICE_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_INVOICE_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_INVOICE_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_INVOICE_BILL_TO_COMPANY_SHOWING_CONTACT_LIST: 'SET_INVOICE_BILL_TO_COMPANY_SHOWING_CONTACT_LIST',
    SET_INVOICE_BILL_TO_COMPANY_CONTACTS: 'SET_INVOICE_BILL_TO_COMPANY_CONTACTS',
    SET_INVOICE_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_INVOICE_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_INVOICE_BILL_TO_COMPANY_IS_EDITING_CONTACT: 'SET_INVOICE_BILL_TO_COMPANY_IS_EDITING_CONTACT',
    SET_INVOICE_SELECTED_BILL_TO_COMPANY_DOCUMENT: 'SET_INVOICE_SELECTED_BILL_TO_COMPANY_DOCUMENT',
    SET_INVOICE_BILL_TO_COMPANY_DOCUMENT_TAGS: 'SET_INVOICE_BILL_TO_COMPANY_DOCUMENT_TAGS',
    SET_INVOICE_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE: 'SET_INVOICE_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE',

    SET_CUSTOMER_BILL_TO_COMPANIES: 'SET_CUSTOMER_BILL_TO_COMPANIES',
    SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_INFO: 'SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_INFO',
    SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_CONTACT: 'SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_CONTACT',
    SET_CUSTOMER_BILL_TO_COMPANY_SEARCH: 'SET_CUSTOMER_BILL_TO_COMPANY_SEARCH',
    SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_NOTE: 'SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_NOTE',
    SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_DIRECTION: 'SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_DIRECTION',
    SET_CUSTOMER_BILL_TO_COMPANY_CONTACT_SEARCH: 'SET_CUSTOMER_BILL_TO_COMPANY_CONTACT_SEARCH',
    SET_CUSTOMER_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_CUSTOMER_BILL_TO_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_CUSTOMER_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_CUSTOMER_BILL_TO_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_CUSTOMER_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_CUSTOMER_BILL_TO_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_CUSTOMER_BILL_TO_COMPANY_SHOWING_CONTACT_LIST: 'SET_CUSTOMER_BILL_TO_COMPANY_SHOWING_CONTACT_LIST',
    SET_CUSTOMER_BILL_TO_COMPANY_CONTACTS: 'SET_CUSTOMER_BILL_TO_COMPANY_CONTACTS',
    SET_CUSTOMER_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_CUSTOMER_BILL_TO_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_CUSTOMER_BILL_TO_COMPANY_IS_EDITING_CONTACT: 'SET_CUSTOMER_BILL_TO_COMPANY_IS_EDITING_CONTACT',
    SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_DOCUMENT: 'SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_DOCUMENT',
    SET_CUSTOMER_BILL_TO_COMPANY_DOCUMENT_TAGS: 'SET_CUSTOMER_BILL_TO_COMPANY_DOCUMENT_TAGS',
    SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE: 'SET_CUSTOMER_SELECTED_BILL_TO_COMPANY_DOCUMENT_NOTE',

    SET_SHIPPER_COMPANIES: 'SET_SHIPPER_COMPANIES',
    SET_SELECTED_SHIPPER_COMPANY_INFO: 'SET_SELECTED_SHIPPER_COMPANY_INFO',
    SET_SELECTED_SHIPPER_COMPANY_CONTACT: 'SET_SELECTED_SHIPPER_COMPANY_CONTACT',
    SET_SHIPPER_COMPANY_SEARCH: 'SET_SHIPPER_COMPANY_SEARCH',
    SET_SELECTED_SHIPPER_COMPANY_NOTE: 'SET_SELECTED_SHIPPER_COMPANY_NOTE',
    SET_SELECTED_SHIPPER_COMPANY_DIRECTION: 'SET_SELECTED_SHIPPER_COMPANY_DIRECTION',
    SET_SHIPPER_COMPANY_CONTACT_SEARCH: 'SET_SHIPPER_COMPANY_CONTACT_SEARCH',
    SET_SHIPPER_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_SHIPPER_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_SHIPPER_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_SHIPPER_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_SHIPPER_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_SHIPPER_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_SHIPPER_COMPANY_SHOWING_CONTACT_LIST: 'SET_SHIPPER_COMPANY_SHOWING_CONTACT_LIST',
    SET_SHIPPER_COMPANY_CONTACTS: 'SET_SHIPPER_COMPANY_CONTACTS',
    SET_SHIPPER_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_SHIPPER_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_SHIPPER_COMPANY_IS_EDITING_CONTACT: 'SET_SHIPPER_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_SHIPPER_COMPANY_DOCUMENT: 'SET_SELECTED_SHIPPER_COMPANY_DOCUMENT',
    SET_SHIPPER_COMPANY_DOCUMENT_TAGS: 'SET_SHIPPER_COMPANY_DOCUMENT_TAGS',
    SET_SELECTED_SHIPPER_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_SHIPPER_COMPANY_DOCUMENT_NOTE',

    SET_LB_SHIPPER_COMPANIES: 'SET_LB_SHIPPER_COMPANIES',
    SET_LB_SELECTED_SHIPPER_COMPANY_INFO: 'SET_LB_SELECTED_SHIPPER_COMPANY_INFO',
    SET_LB_SELECTED_SHIPPER_COMPANY_CONTACT: 'SET_LB_SELECTED_SHIPPER_COMPANY_CONTACT',
    SET_LB_SHIPPER_COMPANY_SEARCH: 'SET_LB_SHIPPER_COMPANY_SEARCH',
    SET_LB_SELECTED_SHIPPER_COMPANY_NOTE: 'SET_LB_SELECTED_SHIPPER_COMPANY_NOTE',
    SET_LB_SELECTED_SHIPPER_COMPANY_DIRECTION: 'SET_LB_SELECTED_SHIPPER_COMPANY_DIRECTION',
    SET_LB_SHIPPER_COMPANY_CONTACT_SEARCH: 'SET_LB_SHIPPER_COMPANY_CONTACT_SEARCH',
    SET_LB_SHIPPER_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_LB_SHIPPER_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_LB_SHIPPER_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_LB_SHIPPER_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_LB_SHIPPER_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_LB_SHIPPER_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_LB_SHIPPER_COMPANY_SHOWING_CONTACT_LIST: 'SET_LB_SHIPPER_COMPANY_SHOWING_CONTACT_LIST',
    SET_LB_SHIPPER_COMPANY_CONTACTS: 'SET_LB_SHIPPER_COMPANY_CONTACTS',
    SET_LB_SHIPPER_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_LB_SHIPPER_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_LB_SHIPPER_COMPANY_IS_EDITING_CONTACT: 'SET_LB_SHIPPER_COMPANY_IS_EDITING_CONTACT',
    SET_LB_SELECTED_SHIPPER_COMPANY_DOCUMENT: 'SET_LB_SELECTED_SHIPPER_COMPANY_DOCUMENT',
    SET_LB_SHIPPER_COMPANY_DOCUMENT_TAGS: 'SET_LB_SHIPPER_COMPANY_DOCUMENT_TAGS',
    SET_LB_SELECTED_SHIPPER_COMPANY_DOCUMENT_NOTE: 'SET_LB_SELECTED_SHIPPER_COMPANY_DOCUMENT_NOTE',

    SET_CUSTOMER_SHIPPER_COMPANIES: 'SET_CUSTOMER_SHIPPER_COMPANIES',
    SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_INFO: 'SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_INFO',
    SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_CONTACT: 'SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_CONTACT',
    SET_CUSTOMER_SHIPPER_COMPANY_SEARCH: 'SET_CUSTOMER_SHIPPER_COMPANY_SEARCH',
    SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_NOTE: 'SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_NOTE',
    SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_DIRECTION: 'SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_DIRECTION',
    SET_CUSTOMER_SHIPPER_COMPANY_CONTACT_SEARCH: 'SET_CUSTOMER_SHIPPER_COMPANY_CONTACT_SEARCH',
    SET_CUSTOMER_SHIPPER_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_CUSTOMER_SHIPPER_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_CUSTOMER_SHIPPER_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_CUSTOMER_SHIPPER_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_CUSTOMER_SHIPPER_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_CUSTOMER_SHIPPER_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_CUSTOMER_SHIPPER_COMPANY_SHOWING_CONTACT_LIST: 'SET_CUSTOMER_SHIPPER_COMPANY_SHOWING_CONTACT_LIST',
    SET_CUSTOMER_SHIPPER_COMPANY_CONTACTS: 'SET_CUSTOMER_SHIPPER_COMPANY_CONTACTS',
    SET_CUSTOMER_SHIPPER_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_CUSTOMER_SHIPPER_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_CUSTOMER_SHIPPER_COMPANY_IS_EDITING_CONTACT: 'SET_CUSTOMER_SHIPPER_COMPANY_IS_EDITING_CONTACT',
    SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_DOCUMENT: 'SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_DOCUMENT',
    SET_CUSTOMER_SHIPPER_COMPANY_DOCUMENT_TAGS: 'SET_CUSTOMER_SHIPPER_COMPANY_DOCUMENT_TAGS',
    SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_DOCUMENT_NOTE: 'SET_CUSTOMER_SELECTED_SHIPPER_COMPANY_DOCUMENT_NOTE',

    SET_CONSIGNEE_COMPANIES: 'SET_CONSIGNEE_COMPANIES',
    SET_SELECTED_CONSIGNEE_COMPANY_INFO: 'SET_SELECTED_CONSIGNEE_COMPANY_INFO',
    SET_SELECTED_CONSIGNEE_COMPANY_CONTACT: 'SET_SELECTED_CONSIGNEE_COMPANY_CONTACT',
    SET_CONSIGNEE_COMPANY_SEARCH: 'SET_CONSIGNEE_COMPANY_SEARCH',
    SET_SELECTED_CONSIGNEE_COMPANY_NOTE: 'SET_SELECTED_CONSIGNEE_COMPANY_NOTE',
    SET_SELECTED_CONSIGNEE_COMPANY_DIRECTION: 'SET_SELECTED_CONSIGNEE_COMPANY_DIRECTION',
    SET_CONSIGNEE_COMPANY_CONTACT_SEARCH: 'SET_CONSIGNEE_COMPANY_CONTACT_SEARCH',
    SET_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_CONSIGNEE_COMPANY_SHOWING_CONTACT_LIST: 'SET_CONSIGNEE_COMPANY_SHOWING_CONTACT_LIST',
    SET_CONSIGNEE_COMPANY_CONTACTS: 'SET_CONSIGNEE_COMPANY_CONTACTS',
    SET_CONSIGNEE_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_CONSIGNEE_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_CONSIGNEE_COMPANY_IS_EDITING_CONTACT: 'SET_CONSIGNEE_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_CONSIGNEE_COMPANY_DOCUMENT: 'SET_SELECTED_CONSIGNEE_COMPANY_DOCUMENT',
    SET_CONSIGNEE_COMPANY_DOCUMENT_TAGS: 'SET_CONSIGNEE_COMPANY_DOCUMENT_TAGS',
    SET_SELECTED_CONSIGNEE_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_CONSIGNEE_COMPANY_DOCUMENT_NOTE',

    SET_LB_CONSIGNEE_COMPANIES: 'SET_LB_CONSIGNEE_COMPANIES',
    SET_LB_SELECTED_CONSIGNEE_COMPANY_INFO: 'SET_LB_SELECTED_CONSIGNEE_COMPANY_INFO',
    SET_LB_SELECTED_CONSIGNEE_COMPANY_CONTACT: 'SET_LB_SELECTED_CONSIGNEE_COMPANY_CONTACT',
    SET_LB_CONSIGNEE_COMPANY_SEARCH: 'SET_LB_CONSIGNEE_COMPANY_SEARCH',
    SET_LB_SELECTED_CONSIGNEE_COMPANY_NOTE: 'SET_LB_SELECTED_CONSIGNEE_COMPANY_NOTE',
    SET_LB_SELECTED_CONSIGNEE_COMPANY_DIRECTION: 'SET_LB_SELECTED_CONSIGNEE_COMPANY_DIRECTION',
    SET_LB_CONSIGNEE_COMPANY_CONTACT_SEARCH: 'SET_LB_CONSIGNEE_COMPANY_CONTACT_SEARCH',
    SET_LB_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_LB_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_LB_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_LB_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_LB_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_LB_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_LB_CONSIGNEE_COMPANY_SHOWING_CONTACT_LIST: 'SET_LB_CONSIGNEE_COMPANY_SHOWING_CONTACT_LIST',
    SET_LB_CONSIGNEE_COMPANY_CONTACTS: 'SET_LB_CONSIGNEE_COMPANY_CONTACTS',
    SET_LB_CONSIGNEE_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_LB_CONSIGNEE_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_LB_CONSIGNEE_COMPANY_IS_EDITING_CONTACT: 'SET_LB_CONSIGNEE_COMPANY_IS_EDITING_CONTACT',
    SET_LB_SELECTED_CONSIGNEE_COMPANY_DOCUMENT: 'SET_LB_SELECTED_CONSIGNEE_COMPANY_DOCUMENT',
    SET_LB_CONSIGNEE_COMPANY_DOCUMENT_TAGS: 'SET_LB_CONSIGNEE_COMPANY_DOCUMENT_TAGS',
    SET_LB_SELECTED_CONSIGNEE_COMPANY_DOCUMENT_NOTE: 'SET_LB_SELECTED_CONSIGNEE_COMPANY_DOCUMENT_NOTE',

    SET_CUSTOMER_CONSIGNEE_COMPANIES: 'SET_CUSTOMER_CONSIGNEE_COMPANIES',
    SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_INFO: 'SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_INFO',
    SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_CONTACT: 'SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_CONTACT',
    SET_CUSTOMER_CONSIGNEE_COMPANY_SEARCH: 'SET_CUSTOMER_CONSIGNEE_COMPANY_SEARCH',
    SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_NOTE: 'SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_NOTE',
    SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_DIRECTION: 'SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_DIRECTION',
    SET_CUSTOMER_CONSIGNEE_COMPANY_CONTACT_SEARCH: 'SET_CUSTOMER_CONSIGNEE_COMPANY_CONTACT_SEARCH',
    SET_CUSTOMER_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_TO: 'SET_CUSTOMER_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_TO',
    SET_CUSTOMER_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_CC: 'SET_CUSTOMER_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_CC',
    SET_CUSTOMER_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_BCC: 'SET_CUSTOMER_CONSIGNEE_COMPANY_AUTOMATIC_EMAILS_BCC',
    SET_CUSTOMER_CONSIGNEE_COMPANY_SHOWING_CONTACT_LIST: 'SET_CUSTOMER_CONSIGNEE_COMPANY_SHOWING_CONTACT_LIST',
    SET_CUSTOMER_CONSIGNEE_COMPANY_CONTACTS: 'SET_CUSTOMER_CONSIGNEE_COMPANY_CONTACTS',
    SET_CUSTOMER_CONSIGNEE_COMPANY_CONTACT_SEARCH_CUSTOMER: 'SET_CUSTOMER_CONSIGNEE_COMPANY_CONTACT_SEARCH_CUSTOMER',
    SET_CUSTOMER_CONSIGNEE_COMPANY_IS_EDITING_CONTACT: 'SET_CUSTOMER_CONSIGNEE_COMPANY_IS_EDITING_CONTACT',
    SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_DOCUMENT: 'SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_DOCUMENT',
    SET_CUSTOMER_CONSIGNEE_COMPANY_DOCUMENT_TAGS: 'SET_CUSTOMER_CONSIGNEE_COMPANY_DOCUMENT_TAGS',
    SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_DOCUMENT_NOTE: 'SET_CUSTOMER_SELECTED_CONSIGNEE_COMPANY_DOCUMENT_NOTE',

    SET_CUSTOMER_OPENED_PANELS: 'SET_CUSTOMER_OPENED_PANELS',
    SET_ADMIN_CUSTOMER_OPENED_PANELS: 'SET_ADMIN_CUSTOMER_OPENED_PANELS',

    SET_ADMIN_CUSTOMER_PANELS: 'SET_ADMIN_CUSTOMER_PANELS',
    SET_COMPANY_CUSTOMER_PANELS: 'SET_COMPANY_CUSTOMER_PANELS',
}

export const dispatchConstants = {
    SET_DISPATCH_PANELS: 'SET_DISPATCH_PANELS',
    SET_SELECTED_ORDER_DOCUMENT: 'SET_SELECTED_ORDER_DOCUMENT',
    SET_ORDER_DOCUMENT_TAGS: 'SET_ORDER_DOCUMENT_TAGS',
    SET_SELECTED_ORDER_DOCUMENT_NOTE: 'SET_SELECTED_ORDER_DOCUMENT_NOTE',
    
    SET_SELECTED_ORDER: 'SET_SELECTED_ORDER',

    SET_CARRIERS: 'SET_CARRIERS',
    SET_SELECTED_CARRIER: 'SET_SELECTED_CARRIER',
    SET_SELECTED_CARRIER_CONTACT: 'SET_SELECTED_CARRIER_CONTACT',

    SET_AE_NUMBER: 'SET_AE_NUMBER',
    SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
    SET_TRIP_NUMBER: 'SET_TRIP_NUMBER',
    SET_DIVISION: 'SET_DIVISION',
    SET_LOAD_TYPE: 'SET_LOAD_TYPE',
    SET_TEMPLATE: 'SET_TEMPLATE',
    SET_PU1: 'SET_PU1',
    SET_PU2: 'SET_PU2',
    SET_PU3: 'SET_PU3',
    SET_PU4: 'SET_PU4',
    SET_PU5: 'SET_PU5',
    SET_DELIVERY1: 'SET_DELIVERY1',
    SET_DELIVERY2: 'SET_DELIVERY2',
    SET_DELIVERY3: 'SET_DELIVERY3',
    SET_DELIVERY4: 'SET_DELIVERY4',
    SET_DELIVERY5: 'SET_DELIVERY5',
    SET_SHIPPER_PU_DATE1: 'SET_SHIPPER_PU_DATE1',
    SET_SHIPPER_PU_DATE2: 'SET_SHIPPER_PU_DATE2',
    SET_SHIPPER_PU_TIME1: 'SET_SHIPPER_PU_TIME1',
    SET_SHIPPER_PU_TIME2: 'SET_SHIPPER_PU_TIME2',
    SET_SHIPPER_BOL_NUMBER: 'SET_SHIPPER_BOL_NUMBER',
    SET_SHIPPER_PO_NUMBER: 'SET_SHIPPER_PO_NUMBER',
    SET_SHIPPER_REF_NUMBER: 'SET_SHIPPER_REF_NUMBER',
    SET_SHIPPER_SEAL_NUMBER: 'SET_SHIPPER_SEAL_NUMBER',
    SET_SHIPPER_SPECIAL_INSTRUCTIONS: 'SET_SHIPPER_SPECIAL_INSTRUCTIONS',
    SET_CONSIGNEE_DELIVERY_DATE1: 'SET_CONSIGNEE_DELIVERY_DATE1',
    SET_CONSIGNEE_DELIVERY_DATE2: 'SET_CONSIGNEE_DELIVERY_DATE2',
    SET_CONSIGNEE_DELIVERY_TIME1: 'SET_CONSIGNEE_DELIVERY_TIME1',
    SET_CONSIGNEE_DELIVERY_TIME2: 'SET_CONSIGNEE_DELIVERY_TIME2',
    SET_CONSIGNEE_SPECIAL_INSTRUCTIONS: 'SET_CONSIGNEE_SPECIAL_INSTRUCTIONS',
    SET_DISPATCH_EVENT: 'SET_DISPATCH_EVENT',
    SET_DISPATCH_EVENT_LOCATION: 'SET_DISPATCH_EVENT_LOCATION',
    SET_DISPATCH_EVENT_NOTES: 'SET_DISPATCH_EVENT_NOTES',
    SET_DISPATCH_EVENT_DATE: 'SET_DISPATCH_EVENT_DATE',
    SET_DISPATCH_EVENT_TIME: 'SET_DISPATCH_EVENT_TIME',
    SET_DISPATCH_EVENTS: 'SET_DISPATCH_EVENTS',
    SET_HAZMAT: 'SET_HAZMAT',
    SET_EXPEDITED: 'SET_EXPEDITED',
    SET_NOTES_FOR_CARRIER: 'SET_NOTES_FOR_CARRIER',
    SET_SELECTED_NOTE_FOR_CARRIER: 'SET_SELECTED_NOTE_FOR_CARRIER',
    SET_INTERNAL_NOTES: 'SET_INTERNAL_NOTES',
    SET_SELECTED_INTERNAL_NOTE: 'SET_SELECTED_INTERNAL_NOTE',
    SET_IS_SHOWING_SHIPPER_SECOND_PAGE: 'SET_IS_SHOWING_SHIPPER_SECOND_PAGE',
    SET_IS_SHOWING_CONSIGNEE_SECOND_PAGE: 'SET_IS_SHOWING_CONSIGNEE_SECOND_PAGE',
    SET_DISPATCH_OPENED_PANELS: 'SET_DISPATCH_OPENED_PANELS',
    SET_ORDER_SELECTED_PICKUP: 'SET_ORDER_SELECTED_PICKUP',
    SET_IS_ADDING_PICKUP: 'SET_IS_ADDING_PICKUP',
    SET_IS_ADDING_DELIVERY: 'SET_IS_ADDING_DELIVERY',
    SET_MILEAGE_LOADER_VISIBLE: 'SET_MILEAGE_LOADER_VISIBLE',
    SET_LB_SELECTED_ORDER: 'SET_LB_SELECTED_ORDER',
    SET_LB_ORDER_NUMBER: 'SET_LB_ORDER_NUMBER',
    SET_LB_TRIP_NUMBER: 'SET_LB_TRIP_NUMBER',
    SET_LB_MILEAGE_LOADER_VISIBLE: 'SET_LB_MILEAGE_LOADER_VISIBLE',
    SET_SHOWING_CHANGE_CARRIER: 'SET_SHOWING_CHANGE_CARRIER',
    SET_NEW_CARRIER: 'SET_NEW_CARRIER',
    SET_IS_SAVING_ORDER: 'SET_IS_SAVING_ORDER',



    SET_CUSTOMER_SELECTED_ORDER: 'SET_CUSTOMER_SELECTED_ORDER',
    SET_CUSTOMER_AE_NUMBER: 'SET_CUSTOMER_AE_NUMBER',
    SET_CUSTOMER_ORDER_NUMBER: 'SET_CUSTOMER_ORDER_NUMBER',
    SET_CUSTOMER_TRIP_NUMBER: 'SET_CUSTOMER_TRIP_NUMBER',
    SET_CUSTOMER_DIVISION: 'SET_CUSTOMER_DIVISION',
    SET_CUSTOMER_LOAD_TYPE: 'SET_CUSTOMER_LOAD_TYPE',
    SET_CUSTOMER_TEMPLATE: 'SET_CUSTOMER_TEMPLATE',
    SET_CUSTOMER_SHIPPER_BOL_NUMBER: 'SET_CUSTOMER_SHIPPER_BOL_NUMBER',
    SET_CUSTOMER_SHIPPER_PO_NUMBER: 'SET_CUSTOMER_SHIPPER_PO_NUMBER',
    SET_CUSTOMER_SHIPPER_REF_NUMBER: 'SET_CUSTOMER_SHIPPER_REF_NUMBER',
    SET_CUSTOMER_DISPATCH_EVENT: 'SET_CUSTOMER_DISPATCH_EVENT',
    SET_CUSTOMER_DISPATCH_EVENT_LOCATION: 'SET_CUSTOMER_DISPATCH_EVENT_LOCATION',
    SET_CUSTOMER_DISPATCH_EVENT_NOTES: 'SET_CUSTOMER_DISPATCH_EVENT_NOTES',
    SET_CUSTOMER_DISPATCH_EVENT_DATE: 'SET_CUSTOMER_DISPATCH_EVENT_DATE',
    SET_CUSTOMER_DISPATCH_EVENT_TIME: 'SET_CUSTOMER_DISPATCH_EVENT_TIME',
    SET_CUSTOMER_DISPATCH_EVENTS: 'SET_CUSTOMER_DISPATCH_EVENTS',
    SET_CUSTOMER_SELECTED_NOTE_FOR_CARRIER: 'SET_CUSTOMER_SELECTED_NOTE_FOR_CARRIER',
    SET_CUSTOMER_SELECTED_INTERNAL_NOTE: 'SET_CUSTOMER_SELECTED_INTERNAL_NOTE',
    SET_CUSTOMER_IS_SHOWING_SHIPPER_SECOND_PAGE: 'SET_CUSTOMER_IS_SHOWING_SHIPPER_SECOND_PAGE',
    SET_CUSTOMER_IS_SHOWING_CONSIGNEE_SECOND_PAGE: 'SET_CUSTOMER_IS_SHOWING_CONSIGNEE_SECOND_PAGE',
    SET_CUSTOMER_MILEAGE_LOADER_VISIBLE: 'SET_CUSTOMER_MILEAGE_LOADER_VISIBLE',
    SET_CUSTOMER_SHOWING_CHANGE_CARRIER: 'SET_CUSTOMER_SHOWING_CHANGE_CARRIER',
    SET_CUSTOMER_NEW_CARRIER: 'SET_CUSTOMER_NEW_CARRIER',
    SET_CUSTOMER_IS_SAVING_ORDER: 'SET_CUSTOMER_IS_SAVING_ORDER',
    SET_CUSTOMER_SELECTED_ORDER_DOCUMENT: 'SET_CUSTOMER_SELECTED_ORDER_DOCUMENT',
    SET_CUSTOMER_ORDER_DOCUMENT_TAGS: 'SET_CUSTOMER_ORDER_DOCUMENT_TAGS',
    SET_CUSTOMER_SELECTED_ORDER_DOCUMENT_NOTE: 'SET_CUSTOMER_SELECTED_ORDER_DOCUMENT_NOTE',

    SET_ADMIN_DISPATCH_PANELS: 'SET_ADMIN_DISPATCH_PANELS',
    SET_COMPANY_DISPATCH_PANELS: 'SET_COMPANY_DISPATCH_PANELS',
}

export const carriersConstants = {
    SET_CARRIERS: 'SET_CARRIERS',
    SET_SELECTED_CARRIER: 'SET_SELECTED_CARRIER',
    SET_CARRIER_PANELS: 'SET_CARRIER_PANELS',
    SET_SELECTED_CARRIER_CONTACT: 'SET_SELECTED_CARRIER_CONTACT',
    SET_SELECTED_CARRIER_NOTE: 'SET_SELECTED_CARRIER_NOTE', //
    SET_SELECTED_CARRIER_DIRECTION: 'SET_SELECTED_CARRIER_DIRECTION', //
    SET_CONTACT_SEARCH: 'SET_CONTACT_SEARCH',
    SET_CARRIER_SEARCH: 'SET_CARRIER_SEARCH',
    SET_SHOWING_CARRIER_CONTACT_LIST: 'SET_SHOWING_CARRIER_CONTACT_LIST', //
    SET_CARRIER_CONTACTS: 'SET_CARRIER_CONTACTS',
    SET_CONTACT_SEARCH_CARRIER: 'SET_CONTACT_SEARCH_CARRIER',
    SET_IS_EDITING_CONTACT: 'SET_IS_EDITING_CONTACT',
    SET_SELECTED_CARRIER_DOCUMENT: 'SET_SELECTED_CARRIER_DOCUMENT', //
    SET_CARRIER_DOCUMENT_TAGS: 'SET_CARRIER_DOCUMENT_TAGS', //
    SET_SELECTED_CARRIER_DOCUMENT_NOTE: 'SET_SELECTED_CARRIER_DOCUMENT_NOTE', //
    SET_DRIVERS: 'SET_DRIVERS',
    SET_SELECTED_DRIVER: 'SET_SELECTED_DRIVER',
    SET_EQUIPMENTS: 'SET_EQUIPMENTS',
    SET_INSURANCE_TYPES: 'SET_INSURANCE_TYPES',
    SET_SELECTED_EQUIPMENT: 'SET_SELECTED_EQUIPMENT',
    SET_SELECTED_INSURANCE_TYPE: 'SET_SELECTED_INSURANCE_TYPE',
    SET_CARRIER_MAILING_ADDRESS: 'SET_CARRIER_MAILING_ADDRESS', //
    SET_FACTORING_COMPANY_SEARCH: 'SET_FACTORING_COMPANY_SEARCH',
    SET_FACTORING_COMPANIES: 'SET_FACTORING_COMPANIES',
    SET_SELECTED_FACTORING_COMPANY: 'SET_SELECTED_FACTORING_COMPANY',
    SET_SELECTED_FACTORING_COMPANY_CONTACT: 'SET_SELECTED_FACTORING_COMPANY_CONTACT',
    SET_SELECTED_FACTORING_COMPANY_CONTACT_SEARCH: 'SET_SELECTED_FACTORING_COMPANY_CONTACT_SEARCH',
    SET_SELECTED_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST: 'SET_SELECTED_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST',
    SET_FACTORING_COMPANY_IS_EDITING_CONTACT: 'SET_FACTORING_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_FACTORING_COMPANY_NOTE: 'SET_SELECTED_FACTORING_COMPANY_NOTE',
    SET_SELECTED_FACTORING_COMPANY_INVOICE_SEARCH: 'SET_SELECTED_FACTORING_COMPANY_INVOICE_SEARCH',
    SET_SELECTED_FACTORING_COMPANY_INVOICES: 'SET_SELECTED_FACTORING_COMPANY_INVOICES',
    SET_CARRIER_INSURANCES: 'SET_CARRIER_INSURANCES',
    SET_SELECTED_INSURANCE: 'SET_SELECTED_INSURANCE',
    SET_SELECTED_DOCUMENT: 'SET_SELECTED_DOCUMENT',
    SET_DOCUMENT_TAGS: 'SET_DOCUMENT_TAGS',
    SET_SELECTED_CARRIER_DOCUMENT_NOTE: 'SET_SELECTED_CARRIER_DOCUMENT_NOTE', //
    SET_EQUIPMENT_INFORMATION: 'SET_EQUIPMENT_INFORMATION',
    SET_FACTORING_COMPANY_CONTACTS: 'SET_FACTORING_COMPANY_CONTACTS',
    SET_SELECTED_FACTORING_COMPANY_INVOICE: 'SET_SELECTED_FACTORING_COMPANY_INVOICE',
    SET_SELECTED_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST: 'SET_SELECTED_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST',
    SET_SELECTED_FACTORING_COMPANY_DOCUMENT: 'SET_SELECTED_FACTORING_COMPANY_DOCUMENT',
    SET_SELECTED_FACTORING_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_FACTORING_COMPANY_DOCUMENT_NOTE',
    SET_FACTORING_COMPANY_DOCUMENT_TAGS: 'SET_FACTORING_COMPANY_DOCUMENT_TAGS',
    SET_CARRIER_OPENED_PANELS: 'SET_CARRIER_OPENED_PANELS',
    SET_CARRIER_RATING: 'SET_CARRIER_RATING',

    SET_ADMIN_CARRIERS: 'SET_ADMIN_CARRIERS',
    SET_ADMIN_SELECTED_CARRIER: 'SET_ADMIN_SELECTED_CARRIER',
    SET_ADMIN_CARRIER_PANELS: 'SET_ADMIN_CARRIER_PANELS',
    SET_ADMIN_SELECTED_CARRIER_CONTACT: 'SET_ADMIN_SELECTED_CARRIER_CONTACT',
    SET_ADMIN_SELECTED_CARRIER_NOTE: 'SET_ADMIN_SELECTED_CARRIER_NOTE', //
    SET_ADMIN_SELECTED_CARRIER_DIRECTION: 'SET_ADMIN_SELECTED_CARRIER_DIRECTION', //
    SET_ADMIN_CONTACT_SEARCH: 'SET_ADMIN_CONTACT_SEARCH',
    SET_ADMIN_CARRIER_SEARCH: 'SET_ADMIN_CARRIER_SEARCH',
    SET_ADMIN_SHOWING_CARRIER_CONTACT_LIST: 'SET_ADMIN_SHOWING_CARRIER_CONTACT_LIST', //
    SET_ADMIN_CARRIER_CONTACTS: 'SET_ADMIN_CARRIER_CONTACTS',
    SET_ADMIN_CONTACT_SEARCH_CARRIER: 'SET_ADMIN_CONTACT_SEARCH_CARRIER',
    SET_ADMIN_IS_EDITING_CONTACT: 'SET_ADMIN_IS_EDITING_CONTACT',
    SET_ADMIN_SELECTED_CARRIER_DOCUMENT: 'SET_ADMIN_SELECTED_CARRIER_DOCUMENT', //
    SET_ADMIN_CARRIER_DOCUMENT_TAGS: 'SET_ADMIN_CARRIER_DOCUMENT_TAGS', //
    SET_ADMIN_SELECTED_CARRIER_DOCUMENT_NOTE: 'SET_ADMIN_SELECTED_CARRIER_DOCUMENT_NOTE', //
    SET_ADMIN_DRIVERS: 'SET_ADMIN_DRIVERS',
    SET_ADMIN_SELECTED_DRIVER: 'SET_ADMIN_SELECTED_DRIVER',
    SET_ADMIN_EQUIPMENTS: 'SET_ADMIN_EQUIPMENTS',
    SET_ADMIN_INSURANCE_TYPES: 'SET_ADMIN_INSURANCE_TYPES',
    SET_ADMIN_SELECTED_EQUIPMENT: 'SET_ADMIN_SELECTED_EQUIPMENT',
    SET_ADMIN_SELECTED_INSURANCE_TYPE: 'SET_ADMIN_SELECTED_INSURANCE_TYPE',
    SET_ADMIN_CARRIER_MAILING_ADDRESS: 'SET_ADMIN_CARRIER_MAILING_ADDRESS', //
    SET_ADMIN_FACTORING_COMPANY_SEARCH: 'SET_ADMIN_FACTORING_COMPANY_SEARCH',
    SET_ADMIN_FACTORING_COMPANIES: 'SET_ADMIN_FACTORING_COMPANIES',
    SET_ADMIN_SELECTED_FACTORING_COMPANY: 'SET_ADMIN_SELECTED_FACTORING_COMPANY',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_CONTACT: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_CONTACT',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_CONTACT_SEARCH: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_CONTACT_SEARCH',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST',
    SET_ADMIN_FACTORING_COMPANY_IS_EDITING_CONTACT: 'SET_ADMIN_FACTORING_COMPANY_IS_EDITING_CONTACT',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_NOTE: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_NOTE',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_INVOICE_SEARCH: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_INVOICE_SEARCH',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_INVOICES: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_INVOICES',
    SET_ADMIN_CARRIER_INSURANCES: 'SET_ADMIN_CARRIER_INSURANCES',
    SET_ADMIN_SELECTED_INSURANCE: 'SET_ADMIN_SELECTED_INSURANCE',
    SET_ADMIN_SELECTED_DOCUMENT: 'SET_ADMIN_SELECTED_DOCUMENT',
    SET_ADMIN_DOCUMENT_TAGS: 'SET_ADMIN_DOCUMENT_TAGS',
    SET_ADMIN_SELECTED_CARRIER_DOCUMENT_NOTE: 'SET_ADMIN_SELECTED_CARRIER_DOCUMENT_NOTE', //
    SET_ADMIN_EQUIPMENT_INFORMATION: 'SET_ADMIN_EQUIPMENT_INFORMATION',
    SET_ADMIN_FACTORING_COMPANY_CONTACTS: 'SET_ADMIN_FACTORING_COMPANY_CONTACTS',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_INVOICE: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_INVOICE',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_DOCUMENT: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_DOCUMENT',
    SET_ADMIN_SELECTED_FACTORING_COMPANY_DOCUMENT_NOTE: 'SET_ADMIN_SELECTED_FACTORING_COMPANY_DOCUMENT_NOTE',
    SET_ADMIN_FACTORING_COMPANY_DOCUMENT_TAGS: 'SET_ADMIN_FACTORING_COMPANY_DOCUMENT_TAGS',
    SET_ADMIN_CARRIER_OPENED_PANELS: 'SET_ADMIN_CARRIER_OPENED_PANELS',
    SET_ADMIN_CARRIER_RATING: 'SET_ADMIN_CARRIER_RATING',

    SET_DISPATCH_CARRIER_INFO_CARRIERS_CHANGING: 'SET_DISPATCH_CARRIER_INFO_CARRIERS_CHANGING',
    SET_DISPATCH_CARRIER_INFO_CARRIER_SEARCH_CHANGING: 'SET_DISPATCH_CARRIER_INFO_CARRIER_SEARCH_CHANGING',

    SET_DISPATCH_CARRIER_INFO_CARRIERS: 'SET_DISPATCH_CARRIER_INFO_CARRIERS',
    SET_DISPATCH_CARRIER_INFO_CARRIER_SEARCH: 'SET_DISPATCH_CARRIER_INFO_CARRIER_SEARCH',
    SET_SELECTED_DISPATCH_CARRIER_INFO_CARRIER: 'SET_SELECTED_DISPATCH_CARRIER_INFO_CARRIER',
    SET_SELECTED_DISPATCH_CARRIER_INFO_CONTACT: 'SET_SELECTED_DISPATCH_CARRIER_INFO_CONTACT',
    SET_SELECTED_DISPATCH_CARRIER_INFO_NOTE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_NOTE',
    SET_SELECTED_DISPATCH_CARRIER_INFO_DIRECTION: 'SET_SELECTED_DISPATCH_CARRIER_INFO_DIRECTION',
    SET_DISPATCH_CARRIER_INFO_CONTACT_SEARCH: 'SET_DISPATCH_CARRIER_INFO_CONTACT_SEARCH',
    SET_DISPATCH_CARRIER_INFO_SHOWING_CONTACT_LIST: 'SET_DISPATCH_CARRIER_INFO_SHOWING_CONTACT_LIST',
    SET_DISPATCH_CARRIER_INFO_CARRIER_CONTACTS: 'SET_DISPATCH_CARRIER_INFO_CARRIER_CONTACTS',
    SET_DISPATCH_CARRIER_INFO_CONTACT_SEARCH_CARRIER: 'SET_DISPATCH_CARRIER_INFO_CONTACT_SEARCH_CARRIER',
    SET_DISPATCH_CARRIER_INFO_IS_EDITING_CONTACT: 'SET_DISPATCH_CARRIER_INFO_IS_EDITING_CONTACT',
    SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT',
    SET_DISPATCH_CARRIER_INFO_DOCUMENT_TAGS: 'SET_DISPATCH_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT_NOTE',
    SET_DISPATCH_CARRIER_INFO_DRIVERS: 'SET_DISPATCH_CARRIER_INFO_DRIVERS',
    SET_SELECTED_DISPATCH_CARRIER_INFO_DRIVER: 'SET_SELECTED_DISPATCH_CARRIER_INFO_DRIVER',
    SET_DISPATCH_CARRIER_INFO_EQUIPMENTS: 'SET_DISPATCH_CARRIER_INFO_EQUIPMENTS',
    SET_DISPATCH_CARRIER_INFO_INSURANCE_TYPES: 'SET_DISPATCH_CARRIER_INFO_INSURANCE_TYPES',
    SET_SELECTED_DISPATCH_CARRIER_INFO_EQUIPMENT: 'SET_SELECTED_DISPATCH_CARRIER_INFO_EQUIPMENT',
    SET_SELECTED_DISPATCH_CARRIER_INFO_INSURANCE_TYPE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_INSURANCE_TYPE',
    SET_DISPATCH_CARRIER_INFO_MAILING_ADDRESS: 'SET_DISPATCH_CARRIER_INFO_MAILING_ADDRESS',
    SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_SEARCH: 'SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_SEARCH',
    SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANIES: 'SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANIES',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_CONTACT: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_CONTACT',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST',
    SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT: 'SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_NOTE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_NOTE',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_INVOICES: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_INVOICES',
    SET_DISPATCH_CARRIER_INFO_CARRIER_INSURANCES: 'SET_DISPATCH_CARRIER_INFO_CARRIER_INSURANCES',
    SET_SELECTED_DISPATCH_CARRIER_INFO_INSURANCE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_INSURANCE',
    SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT',
    SET_DISPATCH_CARRIER_INFO_DOCUMENT_TAGS: 'SET_DISPATCH_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_DOCUMENT_NOTE',
    SET_DISPATCH_CARRIER_INFO_EQUIPMENT_INFORMATION: 'SET_DISPATCH_CARRIER_INFO_EQUIPMENT_INFORMATION',
    SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_CONTACTS: 'SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_CONTACTS',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_INVOICE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_INVOICE',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT',
    SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE',
    SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS: 'SET_DISPATCH_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS',
    SET_DISPATCH_CARRIER_RATING: 'SET_DISPATCH_CARRIER_RATING',

    SET_INVOICE_CARRIER_INFO_CARRIERS: 'SET_INVOICE_CARRIER_INFO_CARRIERS',
    SET_INVOICE_CARRIER_INFO_CARRIER_SEARCH: 'SET_INVOICE_CARRIER_INFO_CARRIER_SEARCH',
    SET_SELECTED_INVOICE_CARRIER_INFO_CARRIER: 'SET_SELECTED_INVOICE_CARRIER_INFO_CARRIER',
    SET_SELECTED_INVOICE_CARRIER_INFO_CONTACT: 'SET_SELECTED_INVOICE_CARRIER_INFO_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_NOTE',
    SET_SELECTED_INVOICE_CARRIER_INFO_DIRECTION: 'SET_SELECTED_INVOICE_CARRIER_INFO_DIRECTION',
    SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH: 'SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH',
    SET_INVOICE_CARRIER_INFO_SHOWING_CONTACT_LIST: 'SET_INVOICE_CARRIER_INFO_SHOWING_CONTACT_LIST',
    SET_INVOICE_CARRIER_INFO_CARRIER_CONTACTS: 'SET_INVOICE_CARRIER_INFO_CARRIER_CONTACTS',
    SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH_CARRIER: 'SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH_CARRIER',
    SET_INVOICE_CARRIER_INFO_IS_EDITING_CONTACT: 'SET_INVOICE_CARRIER_INFO_IS_EDITING_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT',
    SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS: 'SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE',
    SET_INVOICE_CARRIER_INFO_DRIVERS: 'SET_INVOICE_CARRIER_INFO_DRIVERS',
    SET_SELECTED_INVOICE_CARRIER_INFO_DRIVER: 'SET_SELECTED_INVOICE_CARRIER_INFO_DRIVER',
    SET_INVOICE_CARRIER_INFO_EQUIPMENTS: 'SET_INVOICE_CARRIER_INFO_EQUIPMENTS',
    SET_INVOICE_CARRIER_INFO_INSURANCE_TYPES: 'SET_INVOICE_CARRIER_INFO_INSURANCE_TYPES',
    SET_SELECTED_INVOICE_CARRIER_INFO_EQUIPMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_EQUIPMENT',
    SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE_TYPE: 'SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE_TYPE',
    SET_INVOICE_CARRIER_INFO_MAILING_ADDRESS: 'SET_INVOICE_CARRIER_INFO_MAILING_ADDRESS',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_SEARCH: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_SEARCH',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANIES: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANIES',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_NOTE',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICES: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICES',
    SET_INVOICE_CARRIER_INFO_CARRIER_INSURANCES: 'SET_INVOICE_CARRIER_INFO_CARRIER_INSURANCES',
    SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE: 'SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT',
    SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS: 'SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE',
    SET_INVOICE_CARRIER_INFO_EQUIPMENT_INFORMATION: 'SET_INVOICE_CARRIER_INFO_EQUIPMENT_INFORMATION',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACTS: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACTS',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS',
    SET_INVOICE_CARRIER_RATING: 'SET_INVOICE_CARRIER_RATING',

    SET_CUSTOMER_CARRIER_INFO_CARRIERS: 'SET_CUSTOMER_CARRIER_INFO_CARRIERS',
    SET_CUSTOMER_CARRIER_INFO_CARRIER_SEARCH: 'SET_CUSTOMER_CARRIER_INFO_CARRIER_SEARCH',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_CARRIER: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_CARRIER',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_CONTACT: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_CONTACT',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_NOTE: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_NOTE',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_DIRECTION: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_DIRECTION',
    SET_CUSTOMER_CARRIER_INFO_CONTACT_SEARCH: 'SET_CUSTOMER_CARRIER_INFO_CONTACT_SEARCH',
    SET_CUSTOMER_CARRIER_INFO_SHOWING_CONTACT_LIST: 'SET_CUSTOMER_CARRIER_INFO_SHOWING_CONTACT_LIST',
    SET_CUSTOMER_CARRIER_INFO_CARRIER_CONTACTS: 'SET_CUSTOMER_CARRIER_INFO_CARRIER_CONTACTS',
    SET_CUSTOMER_CARRIER_INFO_CONTACT_SEARCH_CARRIER: 'SET_CUSTOMER_CARRIER_INFO_CONTACT_SEARCH_CARRIER',
    SET_CUSTOMER_CARRIER_INFO_IS_EDITING_CONTACT: 'SET_CUSTOMER_CARRIER_INFO_IS_EDITING_CONTACT',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT',
    SET_CUSTOMER_CARRIER_INFO_DOCUMENT_TAGS: 'SET_CUSTOMER_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT_NOTE',
    SET_CUSTOMER_CARRIER_INFO_DRIVERS: 'SET_CUSTOMER_CARRIER_INFO_DRIVERS',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_DRIVER: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_DRIVER',
    SET_CUSTOMER_CARRIER_INFO_EQUIPMENTS: 'SET_CUSTOMER_CARRIER_INFO_EQUIPMENTS',
    SET_CUSTOMER_CARRIER_INFO_INSURANCE_TYPES: 'SET_CUSTOMER_CARRIER_INFO_INSURANCE_TYPES',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_EQUIPMENT: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_EQUIPMENT',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_INSURANCE_TYPE: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_INSURANCE_TYPE',
    SET_CUSTOMER_CARRIER_INFO_MAILING_ADDRESS: 'SET_CUSTOMER_CARRIER_INFO_MAILING_ADDRESS',
    SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_SEARCH: 'SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_SEARCH',
    SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANIES: 'SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANIES',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CONTACT: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CONTACT',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST',
    SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT: 'SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_NOTE: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_NOTE',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CUSTOMER_SEARCH: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CUSTOMER_SEARCH',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CUSTOMERS: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CUSTOMERS',
    SET_CUSTOMER_CARRIER_INFO_CARRIER_INSURANCES: 'SET_CUSTOMER_CARRIER_INFO_CARRIER_INSURANCES',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_INSURANCE: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_INSURANCE',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT',
    SET_CUSTOMER_CARRIER_INFO_DOCUMENT_TAGS: 'SET_CUSTOMER_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_DOCUMENT_NOTE',
    SET_CUSTOMER_CARRIER_INFO_EQUIPMENT_INFORMATION: 'SET_CUSTOMER_CARRIER_INFO_EQUIPMENT_INFORMATION',
    SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CONTACTS: 'SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CONTACTS',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CUSTOMER: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_CUSTOMER',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CUSTOMER_LIST: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CUSTOMER_LIST',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT',
    SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE',
    SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS: 'SET_CUSTOMER_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS',
    SET_CUSTOMER_CARRIER_RATING: 'SET_CUSTOMER_CARRIER_RATING',


    SET_LB_CARRIER_INFO_CARRIERS: 'SET_LB_CARRIER_INFO_CARRIERS',
    SET_SELECTED_LB_CARRIER_INFO_CARRIER: 'SET_SELECTED_LB_CARRIER_INFO_CARRIER',
    SET_SELECTED_LB_CARRIER_INFO_CONTACT: 'SET_SELECTED_LB_CARRIER_INFO_CONTACT',
    SET_SELECTED_LB_CARRIER_INFO_NOTE: 'SET_SELECTED_LB_CARRIER_INFO_NOTE',
    SET_SELECTED_LB_CARRIER_INFO_DIRECTION: 'SET_SELECTED_LB_CARRIER_INFO_DIRECTION',
    SET_LB_CARRIER_INFO_CONTACT_SEARCH: 'SET_LB_CARRIER_INFO_CONTACT_SEARCH',
    SET_LB_CARRIER_INFO_CARRIER_SEARCH: 'SET_LB_CARRIER_INFO_CARRIER_SEARCH',
    SET_LB_CARRIER_INFO_SHOWING_CONTACT_LIST: 'SET_LB_CARRIER_INFO_SHOWING_CONTACT_LIST',
    SET_LB_CARRIER_INFO_CARRIER_CONTACTS: 'SET_LB_CARRIER_INFO_CARRIER_CONTACTS',
    SET_LB_CARRIER_INFO_CONTACT_SEARCH_CARRIER: 'SET_LB_CARRIER_INFO_CONTACT_SEARCH_CARRIER',
    SET_LB_CARRIER_INFO_IS_EDITING_CONTACT: 'SET_LB_CARRIER_INFO_IS_EDITING_CONTACT',
    SET_SELECTED_LB_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_LB_CARRIER_INFO_DOCUMENT',
    SET_LB_CARRIER_INFO_DOCUMENT_TAGS: 'SET_LB_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_LB_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_LB_CARRIER_INFO_DOCUMENT_NOTE',
    SET_LB_CARRIER_INFO_DRIVERS: 'SET_LB_CARRIER_INFO_DRIVERS',
    SET_SELECTED_LB_CARRIER_INFO_DRIVER: 'SET_SELECTED_LB_CARRIER_INFO_DRIVER',
    SET_LB_CARRIER_INFO_EQUIPMENTS: 'SET_LB_CARRIER_INFO_EQUIPMENTS',
    SET_LB_CARRIER_INFO_INSURANCE_TYPES: 'SET_LB_CARRIER_INFO_INSURANCE_TYPES',
    SET_SELECTED_LB_CARRIER_INFO_EQUIPMENT: 'SET_SELECTED_LB_CARRIER_INFO_EQUIPMENT',
    SET_SELECTED_LB_CARRIER_INFO_INSURANCE_TYPE: 'SET_SELECTED_LB_CARRIER_INFO_INSURANCE_TYPE',
    SET_LB_CARRIER_INFO_MAILING_ADDRESS: 'SET_LB_CARRIER_INFO_MAILING_ADDRESS',
    SET_LB_CARRIER_INFO_FACTORING_COMPANY_SEARCH: 'SET_LB_CARRIER_INFO_FACTORING_COMPANY_SEARCH',
    SET_LB_CARRIER_INFO_FACTORING_COMPANIES: 'SET_LB_CARRIER_INFO_FACTORING_COMPANIES',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_CONTACT: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_CONTACT',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST',
    SET_LB_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT: 'SET_LB_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_NOTE: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_NOTE',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_INVOICES: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_INVOICES',
    SET_LB_CARRIER_INFO_CARRIER_INSURANCES: 'SET_LB_CARRIER_INFO_CARRIER_INSURANCES',
    SET_SELECTED_LB_CARRIER_INFO_INSURANCE: 'SET_SELECTED_LB_CARRIER_INFO_INSURANCE',
    SET_SELECTED_LB_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_LB_CARRIER_INFO_DOCUMENT',
    SET_LB_CARRIER_INFO_DOCUMENT_TAGS: 'SET_LB_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_LB_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_LB_CARRIER_INFO_DOCUMENT_NOTE',
    SET_LB_CARRIER_INFO_EQUIPMENT_INFORMATION: 'SET_LB_CARRIER_INFO_EQUIPMENT_INFORMATION',
    SET_LB_CARRIER_INFO_FACTORING_COMPANY_CONTACTS: 'SET_LB_CARRIER_INFO_FACTORING_COMPANY_CONTACTS',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_INVOICE: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_INVOICE',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT',
    SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_LB_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE',
    SET_LB_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS: 'SET_LB_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS',
    SET_LB_CARRIER_RATING: 'SET_LB_CARRIER_RATING',

    SET_INVOICE_CARRIER_INFO_CARRIERS: 'SET_INVOICE_CARRIER_INFO_CARRIERS',
    SET_SELECTED_INVOICE_CARRIER_INFO_CARRIER: 'SET_SELECTED_INVOICE_CARRIER_INFO_CARRIER',
    SET_SELECTED_INVOICE_CARRIER_INFO_CONTACT: 'SET_SELECTED_INVOICE_CARRIER_INFO_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_NOTE',
    SET_SELECTED_INVOICE_CARRIER_INFO_DIRECTION: 'SET_SELECTED_INVOICE_CARRIER_INFO_DIRECTION',
    SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH: 'SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH',
    SET_INVOICE_CARRIER_INFO_CARRIER_SEARCH: 'SET_INVOICE_CARRIER_INFO_CARRIER_SEARCH',
    SET_INVOICE_CARRIER_INFO_SHOWING_CONTACT_LIST: 'SET_INVOICE_CARRIER_INFO_SHOWING_CONTACT_LIST',
    SET_INVOICE_CARRIER_INFO_CARRIER_CONTACTS: 'SET_INVOICE_CARRIER_INFO_CARRIER_CONTACTS',
    SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH_CARRIER: 'SET_INVOICE_CARRIER_INFO_CONTACT_SEARCH_CARRIER',
    SET_INVOICE_CARRIER_INFO_IS_EDITING_CONTACT: 'SET_INVOICE_CARRIER_INFO_IS_EDITING_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT',
    SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS: 'SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE',
    SET_INVOICE_CARRIER_INFO_DRIVERS: 'SET_INVOICE_CARRIER_INFO_DRIVERS',
    SET_SELECTED_INVOICE_CARRIER_INFO_DRIVER: 'SET_SELECTED_INVOICE_CARRIER_INFO_DRIVER',
    SET_INVOICE_CARRIER_INFO_EQUIPMENTS: 'SET_INVOICE_CARRIER_INFO_EQUIPMENTS',
    SET_INVOICE_CARRIER_INFO_INSURANCE_TYPES: 'SET_INVOICE_CARRIER_INFO_INSURANCE_TYPES',
    SET_SELECTED_INVOICE_CARRIER_INFO_EQUIPMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_EQUIPMENT',
    SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE_TYPE: 'SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE_TYPE',
    SET_INVOICE_CARRIER_INFO_MAILING_ADDRESS: 'SET_INVOICE_CARRIER_INFO_MAILING_ADDRESS',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_SEARCH: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_SEARCH',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANIES: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANIES',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACT_SEARCH',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_CONTACT_LIST',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_EDITING_CONTACT',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_NOTE',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE_SEARCH',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICES: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICES',
    SET_INVOICE_CARRIER_INFO_CARRIER_INSURANCES: 'SET_INVOICE_CARRIER_INFO_CARRIER_INSURANCES',
    SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE: 'SET_SELECTED_INVOICE_CARRIER_INFO_INSURANCE',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT',
    SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS: 'SET_INVOICE_CARRIER_INFO_DOCUMENT_TAGS',
    SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_DOCUMENT_NOTE',
    SET_INVOICE_CARRIER_INFO_EQUIPMENT_INFORMATION: 'SET_INVOICE_CARRIER_INFO_EQUIPMENT_INFORMATION',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACTS: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_CONTACTS',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_INVOICE',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_IS_SHOWING_INVOICE_LIST',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT',
    SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE: 'SET_SELECTED_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_NOTE',
    SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS: 'SET_INVOICE_CARRIER_INFO_FACTORING_COMPANY_DOCUMENT_TAGS',

    SET_ADMIN_CARRIER_PANELS: 'SET_ADMIN_CARRIER_PANELS',
    SET_COMPANY_CARRIER_PANELS: 'SET_COMPANY_CARRIER_PANELS',
}

export const invoiceConstants = {
    SET_INVOICE_PANELS: 'SET_INVOICE_PANELS',
    SET_SELECTED_DOCUMENT: 'SET_SELECTED_DOCUMENT',
    SET_DOCUMENT_TAGS: 'SET_DOCUMENT_TAGS',
    SET_SELECTED_DOCUMENT_NOTE: 'SET_SELECTED_DOCUMENT_NOTE',
    SET_INVOICE_OPENED_PANELS: 'SET_INVOICE_OPENED_PANELS',

    SET_INVOICE_SELECTED_ORDER: 'SET_INVOICE_SELECTED_ORDER',
    SET_INVOICE_ORDER_NUMBER: 'SET_INVOICE_ORDER_NUMBER',
    SET_INVOICE_TRIP_NUMBER: 'SET_INVOICE_TRIP_NUMBER',

    SET_INVOICE_INTERNAL_NOTES: 'SET_INVOICE_INTERNAL_NOTES',
    SET_INVOICE_SELECTED_INTERNAL_NOTE: 'SET_INVOICE_SELECTED_INTERNAL_NOTE',

    SET_LB_INVOICE_SELECTED_ORDER: 'SET_LB_INVOICE_SELECTED_ORDER',
    SET_LB_INVOICE_ORDER_NUMBER: 'SET_LB_INVOICE_ORDER_NUMBER',
    SET_LB_INVOICE_TRIP_NUMBER: 'SET_LB_INVOICE_TRIP_NUMBER',

    SET_LB_INVOICE_INTERNAL_NOTES: 'SET_LB_INVOICE_INTERNAL_NOTES',
    SET_LB_INVOICE_SELECTED_INTERNAL_NOTE: 'SET_LB_INVOICE_SELECTED_INTERNAL_NOTE',

    SET_SELECTED_ORDER_INVOICE_CUSTOMER_DOCUMENT: 'SET_SELECTED_ORDER_INVOICE_CUSTOMER_DOCUMENT',
    SET_SELECTED_ORDER_INVOICE_CUSTOMER_DOCUMENT_TAGS: 'SET_SELECTED_ORDER_INVOICE_CUSTOMER_DOCUMENT_TAGS',
    SET_SELECTED_ORDER_INVOICE_CUSTOMER_DOCUMENT_NOTE: 'SET_SELECTED_ORDER_INVOICE_CUSTOMER_DOCUMENT_NOTE',
    SET_SELECTED_ORDER_INVOICE_CARRIER_DOCUMENT: 'SET_SELECTED_ORDER_INVOICE_CARRIER_DOCUMENT',
    SET_SELECTED_ORDER_INVOICE_CARRIER_DOCUMENT_TAGS: 'SET_SELECTED_ORDER_INVOICE_CARRIER_DOCUMENT_TAGS',
    SET_SELECTED_ORDER_INVOICE_CARRIER_DOCUMENT_NOTE: 'SET_SELECTED_ORDER_INVOICE_CARRIER_DOCUMENT_NOTE',

    SET_SELECTED_ORDER_INVOICE_INTERNAL_NOTE: 'SET_SELECTED_ORDER_INVOICE_INTERNAL_NOTE',
    SET_SELECTED_ORDER_INVOICE_BILLING_NOTE: 'SET_SELECTED_ORDER_INVOICE_BILLING_NOTE',

    SET_ADMIN_INVOICE_PANELS: 'SET_ADMIN_INVOICE_PANELS',
    SET_COMPANY_INVOICE_PANELS: 'SET_COMPANY_INVOICE_PANELS',

}

export const loadBoardConstants = {
    SET_LOAD_BOARD_OPENED_PANELS: 'SET_LOAD_BOARD_OPENED_PANELS',
    SET_ADMIN_LOAD_BOARD_PANELS: 'SET_ADMIN_LOAD_BOARD_PANELS',
    SET_COMPANY_LOAD_BOARD_PANELS: 'SET_COMPANY_LOAD_BOARD_PANELS',

    SET_AVAILABLE_ORDERS: 'SET_AVAILABLE_ORDERS',
    SET_BOOKED_ORDERS: 'SET_BOOKED_ORDERS',
    SET_IN_TRANSIT_ORDERS: 'SET_IN_TRANSIT_ORDERS',
    SET_DELIVERED_NOT_INVOICED: 'SET_DELIVERED_NOT_INVOICED',
    SET_IS_LOADING_WIDGET: 'SET_IS_LOADING_WIDGET',
}

export const companySetupConstants = {
    SET_COMPANY_SETUP_OPENED_PANELS: 'SET_COMPANY_SETUP_OPENED_PANELS',
    SET_SELECTED_COMPANY: 'SET_SELECTED_COMPANY',
    SET_SELECTED_EMPLOYEE: 'SET_SELECTED_EMPLOYEE',
    SET_SELECTED_AGENT: 'SET_SELECTED_AGENT',
    SET_SELECTED_AGENT_DRIVER: 'SET_SELECTED_AGENT_DRIVER',
    SET_SELECTED_COMPANY_DRIVER: 'SET_SELECTED_COMPANY_DRIVER',
    SET_SELECTED_DRIVER_CONTACT: 'SET_SELECTED_DRIVER_CONTACT',
    SET_SELECTED_COMPANY_OPERATOR: 'SET_SELECTED_COMPANY_OPERATOR',
    SET_SELECTED_OPERATOR_CONTACT: 'SET_SELECTED_OPERATOR_CONTACT',
    SET_SELECTED_OWNER_OPERATOR: 'SET_SELECTED_OWNER_OPERATOR',
    SET_ADMIN_COMPANY_SETUP_PANELS: 'SET_ADMIN_COMPANY_SETUP_PANELS',
    SET_COMPANY_COMPANY_SETUP_PANELS: 'SET_COMPANY_COMPANY_SETUP_PANELS',
}

export const reportsConstants = {
    SET_ADMIN_REPORT_PANELS: 'SET_ADMIN_REPORT_PANELS',
    SET_COMPANY_REPORT_PANELS: 'SET_COMPANY_REPORT_PANELS',
}